// front/src/components/reports/AgingReport.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../config.json';

const AgingReport = ({ authInfo }) => {
    const [agingData, setAgingData] = useState({ '0-30': [], '31-60': [], '61-90': [], '90+': [] });

    useEffect(() => {
        const fetchAgingReport = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/aging-report`, {
                    headers: { 'auth-token': authInfo.token }
                });
                setAgingData(response.data.data);
            } catch (error) {
                console.error('Error fetching aging report:', error);
            }
        };

        fetchAgingReport();
    }, [authInfo.token]);

    return (
        <div>
            <h2>Aging Report for Debtors</h2>
            {Object.keys(agingData).map(bucket => (
                <div key={bucket}>
                    <h3>{bucket} Days</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Customer ID</th>
                                <th>Customer Name</th>
                                <th>Invoice ID</th>
                                <th>Invoice Date</th>
                                <th>Invoice Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {agingData[bucket].map(invoice => (
                                <tr key={invoice.invoice_id}>
                                    <td>{invoice.customer_id}</td>
                                    <td>{invoice.customer_name}</td>
                                    <td>{invoice.invoice_id}</td>
                                    <td>{invoice.invoice_date}</td>
                                    <td>{invoice.invoice_amount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
};

export default AgingReport;
